import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HeroBgImage = () => {
    return (
        <div className="row g-0 ">
            <div className="d-grid">
                <StaticImage
                    className="hero-image d-block d-sm-none"
                    layout="fullWidth"
                    aspectRatio={16 / 10}
                    transformOptions={{ fit: "cover", cropFocus: "center" }}
                    alt="splash banner vessel"
                    src={"../../../images/other/people.jpg"}
                    formats={["auto", "webp", "avif"]}
                />
                <StaticImage
                    className="hero-image d-none d-sm-block"
                    layout="fullWidth"
                    aspectRatio={16 / 3.5}
                    transformOptions={{ fit: "cover", cropFocus: "center" }}
                    alt="splash banner vessel"
                    src={"../../../images/other/people.jpg"}
                    formats={["auto", "webp", "avif"]}
                />
                <div className="hero-image-overlay d-flex align-items-end justify-content-center">
                    <h1 className="text-center text-white mb-4 mb-md-5">Accountability, Integrity, Reliability.</h1>
                </div>
            </div>
        </div>
    )
}

export default HeroBgImage
