import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Products = () => {
    return (
        <div className="container py-3 py-lg-4">
            <div className="row g-0 mb-3">
                <div className="col border-bottom border-1 border-dark">
                    <h1>Our Products</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-4 mb-2 mb-md-0 ">
                    <div className="card border-0 h-100 card-product ">
                        <div className="card-body d-flex flex-column">
                            <h1 className="text-light display-5 ">1</h1>
                            <h3 className="text-light">ISO-8217 Grades</h3>
                            <div className="text-end border-top border-2 border-light pt-3 mt-auto">
                                <Link to="/energy-logistics/products/iso-8217" className="text-light">
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 mb-2 mb-md-0">
                    <div className="card border-0 h-100 card-product ">
                        <div className="card-body d-flex flex-column">
                            <h1 className="display-5 text-light">2</h1>
                            <h3 className=" text-light">Bio Fuels</h3>
                            <div className="text-end border-top border-2 border-light pt-3 mt-auto">
                                <Link to="/energy-logistics/products/bio-fuels" className="text-light">
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 ">
                    <div className="card border-0 h-100 card-product ">
                        <div className="card-body d-flex flex-column">
                            <h1 className="display-5 text-light">3</h1>
                            <h3 className=" text-light">Carbon Credits</h3>
                            <div className="text-end border-top border-2 border-light pt-3  mt-auto">
                                <Link to="/energy-logistics/products/carbon-credits" className="text-light">
                                    Learn more 
                                    <svg width="0.8em" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-right" class="svg-inline--fa fa-arrow-right fa-w-14 fa-fw ms-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products
